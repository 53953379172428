// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".textInput_input__12Aho {\n  background: #212121;\n  border: 1pt solid rgba(31, 31, 31, 0);\n  padding-left: 10px;\n  padding-right: 10px;\n  border-radius: 4px;\n  color: rgb(189, 189, 189);\n  height: 30px;\n  font-size: 13px;\n  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.2);\n}\n.textInput_input__12Aho:focus {\n  outline: none;\n}\n.textInput_clearButton__31DOM {\n  position: absolute;\n  right: -20px;\n  top: 10px;\n  opacity: 0.3;\n}\n.textInput_clearButton__31DOM:hover {\n  cursor: pointer;\n  opacity: 1;\n}\n", "",{"version":3,"sources":["webpack://src/modules/fc_src/atoms/inputs/textInput/textInput.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,qCAAqC;EACrC,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;EAClB,yBAAyB;EACzB,YAAY;EACZ,eAAe;EACf,gDAAgD;AAClD;AACA;EACE,aAAa;AACf;AACA;EACE,kBAAkB;EAClB,YAAY;EACZ,SAAS;EACT,YAAY;AACd;AACA;EACE,eAAe;EACf,UAAU;AACZ","sourcesContent":[".input {\n  background: #212121;\n  border: 1pt solid rgba(31, 31, 31, 0);\n  padding-left: 10px;\n  padding-right: 10px;\n  border-radius: 4px;\n  color: rgb(189, 189, 189);\n  height: 30px;\n  font-size: 13px;\n  box-shadow: inset 1px 1px 0px rgba(0, 0, 0, 0.2);\n}\n.input:focus {\n  outline: none;\n}\n.clearButton {\n  position: absolute;\n  right: -20px;\n  top: 10px;\n  opacity: 0.3;\n}\n.clearButton:hover {\n  cursor: pointer;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": "textInput_input__12Aho",
	"clearButton": "textInput_clearButton__31DOM"
};
export default ___CSS_LOADER_EXPORT___;
