import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Box } from "@mui/material"

import {
  setActivePage
} from '../../reducers/appSettingsSlice';
import resetPassword from "../../utils/fetch/auth/resetPassword";

import {
  fc_molecules,
  fc_atoms
} from '../../ui';

export const MyAccount = (props) => {

  const {
    Card,
    Modal,
  } = fc_molecules;
  const {
    Button,
    Spinner,
    BlockDataItem,
    TextInput,
  } = fc_atoms;
  const dispatch = useDispatch();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorModalText, setErrorModalText] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [passData, setPassData] = useState({
    oldPass: "",
    newPass: "",
    newPassVal: "",
  });
  useEffect(() => {
    dispatch(setActivePage("my_account"));
  });
  const submit = async () => {
    setSubmitting(true);
    const res = await resetPassword({...passData});
    if (res) {
      setSubmitting(false);
      setPassData({
        oldPass: "",
        newPass: "",
        newPassVal: "",
      });
    } else {
      setSubmitting(false);
      setErrorModalText(res.err);
      setShowErrorModal(true);
    }
  };
  return (
    <Box>
        <Card title="Reset password" style={{ width: 350 }}>
          <BlockDataItem
            title="Old password*"
            style={{
              width: "calc(100% - 40px)",
              marginRight: 40,
              maxWidth: "none",
            }}
          >
            <TextInput
              style={{ width: "100%" }}
              type="password"
              value={passData.oldPass}
              onChange={(val) => {
                const newPassData = Object.assign({}, passData);
                newPassData.oldPass = val;
                setPassData(newPassData);
              }}
            />
          </BlockDataItem>
          <BlockDataItem
            title="New password*"
            style={{
              width: "calc(100% - 40px)",
              marginRight: 40,
              maxWidth: "none",
            }}
          >
            <TextInput
              style={{ width: "100%" }}
              type="password"
              value={passData.newPass}
              onChange={(val) => {
                const newPassData = Object.assign({}, passData);
                newPassData.newPass = val;
                setPassData(newPassData);
              }}
            />
          </BlockDataItem>
          <BlockDataItem
            title="Confirm new password*"
            style={{
              width: "calc(100% - 40px)",
              marginRight: 40,
              maxWidth: "none",
            }}
          >
            <TextInput
              style={{ width: "100%" }}
              type="password"
              value={passData.newPassVal}
              onChange={(val) => {
                const newPassData = Object.assign({}, passData);
                newPassData.newPassVal = val;
                setPassData(newPassData);
              }}
            />
          </BlockDataItem>
          <br />
          <div style={{ width: "calc(100% - 20px)" }} className="inline_center">
            <Button onClick={() => submit()}>
              {submitting ? <Spinner /> : "Submit"}
            </Button>
          </div>
        </Card>
      <Modal visible={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Card title="Error" style={{ width: 350 }}>
          {errorModalText}
          <br />
          <br />
          <br />
          <div
            style={{
              display: "flex",
              width: "calc(100% - 20px)",
              justifyContent: "center",
            }}
          >
            <Button onClick={() => setShowErrorModal(false)}>Ok</Button>
          </div>
        </Card>
      </Modal>
    </Box>
  );
};

export default MyAccount;