import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Box, Button, } from "@mui/material"
import CenteredSpinner from "../../organisms/CenteredSpinner";
import MUIDataTable from "mui-datatables"

// Import FC UI elements
import {
  fc_molecules,
} from '../../ui';

// Fetch API
import CRMGetters from "../../utils/fetch/crm/crmGetters";

// Actions
import {
  setActivePage
} from '../../reducers/appSettingsSlice';

import { 
  setContacts,
} from '../../reducers/contactsSlice';


export const ContactsPage = (props) => {

  const {
    Card,
  } = fc_molecules;

  const ActivePage = useSelector((state) => state.AppSettings.ActivePage);
  const contactResults = useSelector((state) => state.Contacts.contacts);
  const contactsHaveDownloaded = useSelector((state) => state.Contacts.meta.hasDownloaded);

  const dataTableColumns = [
        { name: "honorific", label: "Honorific", options: {sort: true, filter: true}},
        { name: "first", label: "First", options: {sort: true, filter: true}},
        { name: "middle", label: "Middle", options: {sort: true, filter: true}},
        { name: "last", label: "Last", options: {sort: true, filter: true}},
        { name: "suffix", label: "Suffix", options: {sort: true, filter: true}},
        { name: "created", label: "Created", options: {sort: true, filter: true}},
        { name: "updated", label: "Updated", options: {sort: true, filter: true}},
        { name: "id", label: "ID", options: {sort: true, filter: true}},
        { name: "Actions", options: {
            sort: false,
            filter: false,
            customBodyRenderLite: (dataIndex) => {
                let rowId = contactResults[dataIndex].id;
                return (
                    <Link to={`/contacts/contact/${rowId}`}>
                        <Button
                            sx={{fontSize: "11px"}}
                            color="secondary"
                            variant="contained"
                        >View</Button>
                    </Link>
                );
            }
        }},
    ]
  
  const hasDownloaded = useSelector(
    (state) => state.Contacts.meta.hasDownloaded
  );
  const dispatch = useDispatch();
  const crmGetters = new CRMGetters();

  const getInit = async () => {
    const data = await crmGetters.getContacts();
    dispatch(setContacts(data));
  };

  useEffect(() => {
    if (ActivePage !== "contacts") {
      dispatch(setActivePage("contacts"));
    }
    if (!hasDownloaded && !contactResults.length) {
      getInit();
    }
  },[hasDownloaded, contactResults]);

  return (
    <Box sx={{width: "100%", height: "100%"}}>
        <Card
          title="All Contacts"
          style={{ height: "90vh", width: "calc(100vh -235px)"}}
          bodyStyle={{ height: "calc(100% - 60px)" }}
        >
          {
            !contactsHaveDownloaded ?
              <CenteredSpinner />
            :
              <>
                  <Box sx={{paddingLeft: "16px"}}>
                      <MUIDataTable
                        data={contactResults}
                        columns={dataTableColumns}
                        options={{
                            filterType: 'textField',
                            selectableRows: "none",
                            responsive: "standard",
                            print: false,
                      }}
                      />
                  </Box>
              </>
          }
        </Card>
    </Box>
  );
};

export default ContactsPage;